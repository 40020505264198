.background-container {
  width: 100%;
  height: 100%;
  background-color: #fff; /* Set a background color if needed */
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 120%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -1;
}

.load-more-button {
  background: #8f8969;
  border: 3px solid #e1d6c3;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.load-more-button:hover,
.load-more-button:active {
  background-color: initial;
  background-position: 0 0;
  color: #e1d6c3;
}

.load-more-button:active {
  opacity: .5;
}
.whole-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}
@media (max-width: 600px) {
.post-container-limit {
  overflow: hidden;
  word-break: break-word; 
}}

