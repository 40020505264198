.NavBar ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 1%;
  padding-right: 1%;

}

.link {
  font-style: none;
}


.NavBar li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 10%;
}

@media screen and (max-width: 896px) {
 
  .NavBarIcons{
    display: none;
  }
  .NavBarItems {
    display: inline;
    float: left;
    padding-top: 1.4%;
    padding-bottom: 1.3%;
    padding-left: 0%;
  }

  .NavBarItems a {
    overflow: hidden;
    font-size: 100%;
    padding-left: 3.2%;
    text-decoration: none;
    color: white;
  }

  .NavBarMenu{
    display: inline;
    float: left;
    padding-top: 1%;
    width: 100%;
    padding-bottom: 1.3%;
    padding-left: 0%;
  }

  .NavBarMenu a {
    overflow: hidden;
    font-size: 100%;
    padding-left: 2%;
    text-decoration: none;
    color: white;
  }

  .NavBarIcons a {
    float: right;
    padding: 2% 1.3%;
    text-align: center;
    overflow: hidden;
  }

  .NavBarButton {
    float: right;
    padding-left: 0.6%;
    padding: 1.88% 3.2% 0.8% 0.8%;
  }

  .NavBarButton a {
    padding-top: 7%;
    font-size: 100%;
    text-decoration: none;
    color: white;
  }

}

@media screen and (min-width: 896px) and (max-width: 3840px) {
  .NavBarItems {
    display: inline;
    float: left;
    padding-top: 1.4%;
    padding-bottom: 1.3%;
    padding-left: 5rem;
  }

  .NavBarItems a {
    text-align: center;
    overflow: hidden;
    font-size: 100%;
    padding: 14px 8px;
    text-decoration: none;
    color: white;
  }

  .NavBarMenu {
    display: inline;
    float: left;
    padding-top: 1.4%;
    padding-bottom: 1.3%;
    padding-left: 5rem;
  }

  .NavBarMenu a {
    text-align: center;
    overflow: hidden;
    font-size: 100%;
    padding: 14px 8px;
    text-decoration: none;
    color: white;
  }
  .NavBarIcons a {
    float: right;
    padding: 1.5% 0.3%;
    text-align: center;
    overflow: hidden;
  }
  .hide-on-mobile {
    display: block;
  }

  .show-on-mobile, .hide-on-pc {
    display: none;
  }

  .NavBarButton {
    float: right;
    padding-left: 0.6%;
    padding: 1.50% 7.2% 0.8% 0.8%;
  }

  .NavBarButton a {
    padding-top: 7%;
    font-size: 100%;
    text-decoration: none;
    color: white;
  }



}

.NavBar ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 1%;
  padding-right: 1%;
}

.link {
  font-style: none;
}

.NavBar li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 10%;
}


.hide, .hide-on-mobile {
  display: none;
}

.show-on-mobile {
  display: block;
}

@media screen and (max-width: 896px) {
  
  .NavBarMenu{
    display: inline-block;
  }
  .NavBarButton, .NavBarIcons {
    display: block; /* Always show */
  }

  .HamburgerMenu {
    display: block; /* Show on small screens */
  }
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
 
  .NavBarIcons {
    padding-left: 3%;
  } 
  .NavBarIcons a {
    float: left;
    padding-left: 2%;
    
    
  }

}

@media screen and (min-width: 896px) {
  .NavBarItems {
    display: block; /* Show on large screens */
  }

  .NavBarButton, .NavBarIcons {
    display: block; /* Always show */
  }

  .HamburgerMenu {
    display: none; /* Hide on large screens */
  }
  .NavBarLogo img {
    height: 4em; 
    /* Adjust the size of the logo */
  }
  .NavBarLogo a img:hover {
    height: 4.2em; /* Make the logo a bit bigger when hovering */
    /*box-shadow: 0 0 10px white; /* Add a white glow around the logo */
  }

  .hide-on-mobile {
    display: block;
  }
  .show-on-mobile {
    display: none;
  }
  
  
}



.show-on-mobile {
  display: block;
}

.HamburgerMenu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.HamburgerMenu div {
  width: 1.5rem;
  height: 0.2rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.HamburgerMenuOpen div:first-child {
  transform: rotate(45deg);
}

.HamburgerMenuOpen div:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.HamburgerMenuOpen div:nth-child(3) {
  transform: rotate(-45deg);
}

@media screen and (max-width: 896px) {
  .HamburgerMenu {
    display: flex;
  }
  .NavBarItemsHidden {
    display: none;
  }
  .NavBarLogo img {
    height: 2.5em; 
    /* Adjust the size of the logo */
  }
  .NavBarLogo a img:hover {
    height: 3em; 
  }
  
}
.NavBarLogo {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

