/* PostDetail.css */
.post-detail-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears on top of other content */
}

.post-detail-container {
  position: relative;
  padding: 20px;
  padding-top: 15px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: auto;
  max-width: 80%;
  min-width: 60%;
  max-height: 80%;
  min-height: 70%;
  overflow: auto;

  /* Custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #a99886 transparent; /* Subtle brown colors */
}

/* Hide scrollbar for WebKit/Blink */
.post-detail-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.post-detail-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.post-detail-container::-webkit-scrollbar-thumb {
  background: #a99886; /* Subtle brown */
  border-radius: 8px; /* Rounded corners */
}

/* Handle on hover */
.post-detail-container::-webkit-scrollbar-thumb:hover {
  background: #8b7262; /* Darker subtle brown */
}

.close-button {
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: rgb(43, 30, 23)
}

.options-button{
    position: absolute;
    top:6px;
    bottom: 10px;
    left: 10px;
    font-size: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: rgb(43, 30, 23);
    text-align: center;
    height: 2rem;
}


.post-image{
  float: left;
  padding-right: 2%;
  max-height: 50%;
  max-width: 50%;
}

.post-image img{
  max-height: 40rem;
  max-width: 100%;
  border-radius: 10px;
}
.edit-button {
  width: 45px; /* Adjust the width and height to fit your image */
  height: 45px;
  background-color: #0fbf3e; /* Set background color as transparent */
  border: none;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  padding: 0; /* Remove default padding */
}

.edit-button img {
  width: 60%; /* Make the image fill the button */
  height: 60%; /* Make the image fill the button */
 /* Keep the image circular */
}

.edit-button:focus {
  outline: none; /* Remove focus outline */
}

.edit-button:hover {
  background: linear-gradient(45deg, #45a049, #2E8B57); /* Darker Gradient */
}


.options-window {
  position: absolute;
  /* top: 2rem; Adjust as needed */
  left: 5px;
  top:40px;
  background: #e0d6c2;
  border: 2px solid #976d55;
  border-radius: 4px;
  padding: 10px;
  z-index: 1000; /* Ensure it appears on top of other content */
  display: flex;
  flex-direction: column;
}

.options-window::before {
  content: "";
  position: absolute;
  top: -10px; /* Position the arrow */
  left: 8px; /* Adjust as needed */
  border-width: 0 10px 10px 10px; /* Create the arrow */
  border-style: solid;
  border-color: transparent transparent #976d55 transparent; /* Arrow color */
}

.options-window::after {
  content: "";
  position: absolute;
  top: -8.5px; /* Position the arrow */
  left: 9px; /* Adjust as needed */
  border-width: 0 9px 9px 9px; /* Create the arrow */
  border-style: solid;
  border-color: transparent transparent #e0d6c2 transparent; /* Arrow color */
}


@media (max-width: 600px) {
  .post-detail {
    display: flex;
    flex-direction: column;
  }

  .post-detail .post-header {
    word-break: break-word; 
    order: 1;
  }

  .post-detail .post-image {
    order: 2;
  }

  .post-detail .post-content{
    order: 3;
  }
  
  .post-detail-container {
    max-width: 94%;
   max-height: 90%;
   padding-left: 10px;
   padding-right: 10px;
  }
  .post-image{
    float: left;
    padding-right:0;
    max-height: auto;
    max-width: 100%;
  }
  
  .post-image img{
    max-height: 40rem;
    max-width: 100%;
    border-radius: 10px;
  }
  
}



/* Add this to your PostDetail.css file */

/* Style for the input fields in edit mode */
.post-detail input[type="text"],
.post-detail textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays inside the border */
  margin-top: 6px; /* Add some space between input fields */
  margin-bottom: 16px; /* Add some space below the input fields */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style for the save button in edit mode */
.post-detail button.save-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  border: none; /* No border */
  border-radius: 4px; /* Rounded borders */
  cursor: pointer; /* Add a pointer cursor on mouse-over */
}

/* Add a background color to the save button on mouse-over */
.post-detail button.save-button:hover {
  background-color: #45a049;
}
.empty-input {
  border: 1px solid red;
}

.disabled-button {
  background-color: grey;
  color: white;
}

.edit-button-ba {
  background-color: #799D83; /* Green */
  border: none;
  color: white;
  padding: 10px 20px; /* Adjust the padding to make the button smaller */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px; /* Adjust the font size to make the button smaller */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px; /* Add border-radius to make the corners rounded */
}

.delete-button {
  background-color: #D66E61; /* Red */
  border: none;
  color: white;
  padding: 10px 20px; /* Adjust the padding to make the button smaller */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px; /* Adjust the font size to make the button smaller */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px; /* Add border-radius to make the corners rounded */
}

.timestamp {
  font-size: 0.9em; /* Adjust the font size */
  color: #888; /* Set a gray color */
  margin-top: 7px; /* Add some space above the timestamp */
  margin-bottom: 10px; /* Add some space below the timestamp */
  font-style: italic; /* Make the text italic */
  
}

.post-detail h2{
  margin: 0%;
  margin-bottom: 0%;
}

.post-detail-header {
  order: 2;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Space between timestamp and tags */
}


.tags-container {
  display: flex;
  gap: 3px; /* Space between tags */
  flex-wrap: wrap; /* Wrap tags to the next line if they overflow */
}

.tags-in-post {
 
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  appearance: none; /* Remove default arrow in other browsers */
  background-color: #048f29; /* Light green color */
  border: none; /* No border */
  border-radius: 10px; /* More rounded corners */
  padding: 3px 6px; /* Smaller padding */
  color: #fff; /* White text color */
  font-weight: 400; /* Slightly thicker text */
  font-size: 0.8em; /* Smaller font size */
  font-family: 'Roboto', sans-serif;
  /* Remove bottom margin */
  display: inline-block; /* Ensure the width fits the content */
  transition: width 0.3s; /* Smooth transition for width change */
}


.no-scroll {
  overflow: hidden;
}
.creator-name {
  font-size: 0.9em; /* Adjust the font size */
  color: #888; /* Set a gray color */
  /* margin-top: 5px; Add some space above the creator's name */
  /* margin-bottom: 10px; Add some space below the creator's name */
  font-style: italic; /* Make the text italic */
  
}
.post-detail-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.left-section {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.center-section {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden; /* Ensure the text doesn't overflow */
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.creator-name {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}