.container-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
}








.container-ban .header{
  font-size: 4rem;
  margin-bottom: 0px;
  font-family: 'Ysabeau Infant', sans-serif;
  color: aliceblue;
  line-height: 0.8;
}
.container-ban .subheader {
  font-size: 2rem;
  margin-bottom: 0px;
  font-family: 'Ysabeau Infant', sans-serif;
  color: aliceblue;
  line-height: 0.9;
  padding-left: 40%;

}

.container-ban .text {
  font-family: 'Ysabeau Infant', sans-serif;
  font-size: 1.5rem;
  color: aliceblue;
}
/* @media screen and (max-width: 850px){
  .container-ban .images-container {
    display: flex;
    align-items: center;
    width: 70vw;
    position: relative;
    justify-content: space-between;
  }
  
  .container-ban .image {
    width: 15vw;
    height: auto;
    border-radius: 10px;
  }

  .container-ban .middle-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .container-ban {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
} */
@media screen and (min-width: 200px) and (max-width: 606px) {

  .container-ban .images-container {
    flex-direction: column;
    align-items: center;
    
  }

  
  .container-ban .image {
    width: 50vw;
    height: auto;
    border-radius: 10px;
  }

  .container-ban .middle-content {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .container-ban {
    display: flex;
    padding-top: 13%;
    justify-content: center;
    
  }

}
@media screen and (min-width: 607px) and (max-width: 896px) {

  .container-ban .images-container {
    flex-direction: column;
    align-items: center;
    
  }

  
  .container-ban .image {
    width: 35vw;
    height: auto;
    border-radius: 10px;
  }

  .container-ban .middle-content {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .container-ban {
    display: flex;
    padding-top: 17%;
    justify-content: center;
    
  }

}
@media screen and (min-width: 896px) and (max-width: 3840px){
  .container-ban .images-container {
    display: flex;
    align-items: center;
    width: 70vw;
    position: relative;
    justify-content: space-between;
  }
  
  .container-ban .image {
    width: 15vw;
    height: auto;
    border-radius: 10px;
  }

  .container-ban .middle-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .container-ban {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
