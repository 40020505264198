.signIn-signUp-container h2 {
  font-size: 24px;
  color: black;
}

.filler-neh {
  display: block;
  width: 94%;
  padding: 3%;
  margin-bottom: 10px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  outline: none;
}

.signIn-signUp-container input {
  display: block;
  width: 94%;
  padding: 3%;
  margin-bottom: 10px;
  border: 0;
  border-bottom: 2px solid gray;
  color: black;
  background: transparent;
  outline: none;
  
  
  
}
.signIn-signUp-container input:not(:focus) {
  border-width: 2px ;
  /* animation: gradientAnimationLow 5s infinite; */
  border-image: linear-gradient(to bottom right, #1e2126, #d1d5da);
  border-image-slice: 1;
}


.signIn-signUp-container input:focus{
  border-width: 2.5px;
  animation: gradientAnimation 7s infinite;
  /* border-image: linear-gradient(to bottom right,  #151515, #929394); */
  border-image-slice: 1;
  /* border-bottom: 2px solid linear-gradient(to right, #11998e, #38ef7d); */
}
.signIn-signUp-container input
{
  &:required,&:invalid { box-shadow:none; }
}

@keyframes gradientAnimation {
  0% {
    border-image-source: linear-gradient(to  right, #1e2126, #383c41);
  }
  25% {
    border-image-source: linear-gradient(to right, #383c41, #d1d5da);
  }
  50% {
    border-image-source: linear-gradient(to  right, #d1d5da, #383c41);
  }
  75% {
    border-image-source: linear-gradient(to  right, #383c41, #383c41);
  }
  100% {
    border-image-source: linear-gradient(to  right, #f1f4f7, #383c41);
  }
}

@keyframes gradientAnimationLow {
  0%, 100% {
    border-image-source: linear-gradient(to bottom right, #1e2126, #d1d5da);
  }
  50% {
    border-image-source: linear-gradient(to bottom right, #d1d5da,  #1e2126);
  }
  
}

.regpri:hover {
  transform: scale(1.1);
  color: white; /* Increase size on hover */
  text-shadow: 0 0 4px rgb(32, 31, 31);/* Add shadow on hover */
}

.regpri{
  color: #1e2126;
}

.button-link {
  text-decoration: none;
}


.point{
  padding-left: 8px;
  padding-right: 8px;
}

.signIn-signUp-container button {
  display: block;
  width: 50%;
  padding: 10px;
  /* padding-bottom: 3%; */
  margin-bottom: 10px;
  background: linear-gradient(to bottom right, #1e2126, #828487);
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 auto;
}



.signIn-signUp-container button:hover,
.signIn-signUp-container button:focus {
  background: linear-gradient(to bottom right, #1e2126, #1e2126 );
  box-shadow: rgba(0, 0, 0, 0.5) 0 4px 12px;
}

.signIn-signUp-container button:hover {
  transform: translateY(-1px);
}



.signIn-signUp-container p {

  cursor: pointer;
}

.signIn-signUp-container .error-message {
  color: red;
  margin-bottom: 10px;
}

.container-neh {
  animation: circling-shadow 5s linear infinite;
  display: flex;
  position: relative; /* Ensure a relative position for stacking context */
  width: 100%;
  /* padding: 20px; */
  overflow: hidden ;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.482); /* Semi-transparent background color */
}

.image-container-neh {
 
  width: 50%;
  overflow: hidden;
  height: 100%;
  
}

.form-container h2 {
  /* margin-top: 0;
  margin-bottom: 0; */
  color: #1e2126;
  text-align: center;
}


.forgot-password-link p{
  color: #1e2126;;
}
.forgot-password-link p:hover{
  transform: scale(1.1);
  color: white; /* Increase size on hover */
  text-shadow: 0 0 4px rgb(32, 31, 31);/* Add shadow on hover */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1; /* Ensure the background image is behind the other elements */
}

@media screen and (min-width: 1px) {
  .tesxt-dole {
    position: absolute;
    bottom: 0;
    top: 90%;
    left: 10%;
    transform: translate(0);
  }

  .tesxt-dole p{
    margin: 0;
    float: left;
    padding: 2x;
  }

  .forgot-password-link {
    position: absolute;
    bottom: 0;
    top: 80%;
    left: 10%;
    transform: translate(0);
  }

  .forgot-password-link p{
    margin: 0;
    float: left;
    padding: 2x;
  }
}

.zly_email {
  float: right;
  color: red;
}




@media screen and (max-width: 750px) {
  .container-neh{
    height: 28rem;
  }
  .tesxt-dole p {
    padding: 3px;
    font-size: 90%;
    float: left;
  }

  .tesxt-dole {
    width: 100%;
    margin: 0 auto;
    z-index: 3;
  }
  .forgot-password-link {
    width: 100%;
    margin: 0 auto;
  }
  .forgot-password-link p{
    padding: 3px;
    font-size: 90%;
  }

  .signIn-signUp-container {
    height: 33rem;
    width: 95%;
    margin: 0 auto;
    margin-top: 2.5%;
    margin-bottom: auto;
    box-sizing: border-box;
    display: flex;
  }

  .form-container {
    float: right;
    width: 90%;
    height: 80%;
    margin: auto;
    position: relative;
    /* padding-bottom: 2%; */
  }

  .neh-from {
    width: 90%;
    padding-left: 5%;
    padding-top: 0%;
    padding-bottom: 2%;
  }
  .signIn-signUp-container input {
 
    margin-bottom: 10px;
    border: 0;
    border-bottom: 2px solid gray;
    color: black;
    background: transparent;
    outline: none;
  }

  .forgot-password-link {
    position: absolute;
    bottom: 0;
    top: 100%;
    left: 10%;
    transform: translate(0);
  } 
 
}

@media screen and (min-width: 750px) and (max-width: 1300px) {
  /* .form-container h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: #1e2126;
    text-align: center;
  } */

  .signIn-signUp-container {
    height: 28rem;
    width: 49rem;
    margin: 0 auto;
    margin-top: 2%;
    box-sizing: border-box;
    display: flex;
    
  }
  /* .form-container {
    float: right;
    width: 50%;
    height: 70%;
    margin: auto;
    position: relative;
  }

   .tesxt-dole {
    position: absolute;
    top: 115%;
    
    
  }
  
  .forgot-password-link {
    
    bottom: 0;
    top: 125%;
    left: 10%;
    transform: translate(0);
  }  */
 

  .image-container-neh img {
    overflow: hidden;
    height: auto;
    width: 100%;
    float: left;
  }

  /* .neh-from {

    padding-left: 15%;
    padding-right: 15%;
    
    padding-bottom: 5%;
  } */
  .form-container h2{
    margin: 0;
  }
  .container-neh{
    height: 28rem;
  }
  .tesxt-dole p {
    padding: 3px;
    font-size: 90%;
    float: left;
  }

  .tesxt-dole {
    width: 100%;
    margin: 0 auto;
    z-index: 3;
  }
  .forgot-password-link {
    width: 100%;
    margin: 0 auto;
  }
  .forgot-password-link p{
    padding: 3px;
    font-size: 90%;
  }

  .form-container {
    float: right;
    width: 50%;
    height: 70%;
    margin: auto;
    position: relative;
  }

  .neh-from {
    width: 90%;
    padding-left: 5%;
    padding-top: 0%;
    padding-bottom: 2%;
  }

  .forgot-password-link {
    position: absolute;
    bottom: 0;
    top: 100%;
    left: 10%;
    transform: translate(0);
  } 

  
}


@media screen and (min-width: 1300px) and (max-width: 3200px) {
  .signIn-signUp-container {
  
    height: 33rem;
    width: 60rem;
    margin: auto;
    /* margin-top: 2.5%; */
    /* margin-bottom: auto; */
    box-sizing: border-box;
    display: flex;
  }

  .image-container-neh img {
    overflow: hidden;
    height: auto;
    width: 100%;
    float: left;
  }

  .neh-from {

    padding-left: 15%;
    padding-right: 15%;
    padding-top: 1rem;
    padding-bottom: 5%;
  }

  .form-container {
    float: right;
    width: 50%;
    height: 80%;
    margin: auto;
    position: relative;
  }
}

/* Style for input fields and error messages */
.error-container {
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 6px;
}

/* Apply this class to input fields when there's an error */
.error-input {
  border: 1px solid red;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

@keyframes circling-shadow {
  0%, 100% {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }
  25% {
    box-shadow: 20px 0 15px rgba(0, 0, 0, 0.8);
  }
  50% {
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.8);
  }
  75% {
    box-shadow: -20px 0 15px rgba(0, 0, 0, 0.8);
  }
}


.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5); /* Darker overlay for more focus on the modal */
}

.modal-content {
  background-color: #fff;
  margin: 10% auto; /* Adjusted for better vertical alignment */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  width: 40%; /* Reduced width for a narrower appearance */
  max-width: 500px; /* Ensures modal is not too wide on larger screens */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
}


.modal-content .modal-email-input {
  width: calc(100% - 24px); /* Full width minus padding */
  padding: 10px 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.modal-error-message {
  color: #d32f2f; /* Error color, can be adjusted */
  margin-top: 5px;
}
