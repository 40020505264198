.button-container {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 200px; /* Adjust the button width as needed */
    animation: jump 1s infinite;
  }
 
  
   
  
  @keyframes jump {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-10px);
    }
    60% {
      transform: translateX(-50%) translateY(-5px);
    }
  }
  
  .arrow-button {
    margin: auto;
    background-color: transparent; /* Transparent background */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: #333; /* Text color */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .arrow-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: -12px; /* Adjust the distance from the text as needed */
  }
  
  .arrow-image {
    /* padding-left: 75%; */
    /* align-items: center;
    justify-content: center; */
    
    width: 100px; /* Adjust the image width as needed */
    height: auto; /* Maintain aspect ratio */
  } 
  .arrow-button p{
    color: aliceblue;
    margin-bottom: 25px;
    /* background-color: aqua; */
    /* padding-left: 75%; */
  }
  
  /* Additional styles for the text */
  