/* Post component */
.post {
  background-color: #fff; /* Default background color */
  /* transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s; */
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 15px;
  overflow: hidden;
  margin: 10px;
  width: 600px; /* Change width to 100% */
  max-width: 600px; /* Set maximum width */
  height: auto; /* Automatically adjust height */
}


/* Image container inside the post */

.post-text {
  margin-top: auto;
  height: 130px; 
  overflow: hidden;
}

.post-imagee{
    width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  overflow: hidden;
}
@media screen and (min-width: 600px){
  .flex-containeros {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
  }
  .post-image-container {
    flex: 1;
    max-width: 200px;
    height: 180px;
    overflow: hidden;
  }
  .post:hover {
    transform: scale(1.05); /* Slightly scale up the post on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a subtle shadow on hover */
    background-color: rgba(255, 255, 255, 0.95); /* Lighten the background on hover */
  }
  
  
}

.h2post{
  font-size: 20px;
  overflow: hidden;
  color: #261903;
  margin-top: 0;
}
/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .post {
    width: calc(100% - 50px); /* Adjust width for smaller screens */
    /* height: calc(80%-50px); */
    max-height: 700px;
    margin: 10px auto; /* Center the post */
  }
  .post-image-container {
    /* justify-content: center;
  align-items: center; */
    /* flex: 1; */
   width: 100%;
    max-height: 600px;
    overflow: hidden;
  }
  .post-imagee{
    /* width: auto; */
    object-fit: contain;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 5px;
}
  .post-text {
    margin-top: auto;
    max-height: 100px; 
    overflow: hidden;
  }
  .flex-containeros {
    
    gap: 20px;
    padding-bottom: 10px;
  }
  .post:hover {
    transform: 0;
  }
  
}
