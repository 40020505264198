.create-post-container {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 3%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  padding: 30px;
}

.background-image-add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
}

.create-post-container h2 {
  margin-top: 0;
}

.error {
  border: 1px solid red;
}

.create-post-container input,
.create-post-container textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  max-width: 100%;
}

.create-post-container textarea {
  height: 240px;
  resize: vertical;
}

.submit-button {
  background-color: #A0522D;
  color: white;
  border: none;
  display: flex;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #8B4513;
}

.create-post-button {
  padding-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}

.image-upload-field {
  border: 3px dashed #ccc;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 20px;
}

.image-upload-field label {
  cursor: pointer;
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
}




.error-message {
  color: red;
  font-size: 14px;
}

.create-post-container select {
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  appearance: none; /* Remove default arrow in other browsers */
  background-color: #048f29; /* Light green color */
  border: none; /* No border */
  border-radius: 10px; /* More rounded corners */
  padding: 5px;
  color: #fff; /* White text color */
  font-weight: 400; /* Slightly thicker text */
  min-width: 35px; /* Minimum width */
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
  transition: width 0.3s; /* Smooth transition for width change */
}

.create-post-container select:focus {
  outline: none;
}

.create-post-container select option {
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  appearance: none; /* Remove default arrow in other browsers */
  background-color: #048f29; /* Light green color */
  border: none; /* No border */
  border-radius: 30px; /* More rounded corners */
  padding: 5px;
  color: #fff; /* White text color */
  font-weight: 400; /* Slightly thicker text */
  min-width: 35px; /* Minimum width */
  font-family: 'Roboto', sans-serif;
}
.centered-select {
  text-align: center;
  text-align-last: center; /* For modern browsers */
  background-color: #048f29; /* Light green color */
  border: none; /* No border */
  border-radius: 10px; /* More rounded corners */
  padding: 5px;
  color: #fff; /* White text color */
  font-weight: 400; /* Slightly thicker text */
  min-width: 35px; /* Minimum width */
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
  transition: all 0.3s ease; /* For modern browsers */
}

.centered-select:hover {
  transform: scale(1.05); /* Slightly increase size */
  box-shadow: 0 0 10px rgba(0, 128, 0, 0.5); /* Green shadow */
  cursor: default; /* Default cursor */
}

.tag-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust spacing between rows */
}

.toggle-button {
 
  cursor: pointer; /* Pointer cursor for button */
  /* Match the color of the select element */
  color: greenyellow; /* White text color */
  border: none; /* No border */
  background-color: transparent;
 font-size: larger;
 margin-left: -5px;
 margin-bottom: 5px;
  font-weight: bold; /* Make the button text bold */
}
.tag-container select {
  margin-right: 5px; /* Space between select elements */
}